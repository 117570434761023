import { useEffect, useState } from "react";  
 
export const parseValor=( num,decimal=2)=>{
    var numN = {
        p:0,
        d:0,
        f:0, 
        fv:0
    } 
    if(num>=0){  
        var valor = parseFloat(num).toFixed((decimal+2)).toString(); 
        valor     =   valor.substring(0, valor.length - 2);  
        var pos   =   parseFloat(valor).toString().split('.'); 
        numN.p    = pos[0].replace(/(.)(?=(\d{3})+$)/g,'$1.'); 
        numN.d    = (typeof pos[1] =='undefined'?0:pos[1]);
        numN.f    =  numN.p+','+numN.d 
        numN.fv    =  numN.p+'.'+numN.d 
        return numN;
    }else{
      return numN;
    }  
}
export const TempoCronometro = (props)=>{
    const [time,setTime] = useState(props.time)
    useEffect(()=>{
        let interV = setInterval(()=>{ 
            setTime((props.time - new Date().getTime())) 
        },1000);
        return ()=>{
            clearInterval(interV);
        }
    },[time,props.time]) 
    
    var msg = '';
    var d  = {
        d:0,
        h:0,
        m:0,
        s:0
    }
    var t = ((props.time - new Date().getTime())*0.001).toString().split('.')[0];
    if(props.view =='tempo'){
        if(t<0){
            t*=-1;
        }
        d.d = parseInt((t/86400).toString().split('.')[0]);
        var s=t%86400;
        d.h = parseInt((s/3600).toString().split('.')[0]);
        s=s%3600;
        d.m = parseInt((s/60).toString().split('.')[0]);
        d.s=s%60; 
        if(d.d>0){
            msg +=d.d>1?d.d+' dias - ':d.d+' dia - ';
            if(t>3600){
                msg +=d.h+'h ';
            }
        }else{
            if(t>3600){
                msg +=d.h+'h ';
            }
            if(t>60){
                msg +=(60-d.m)+'m ';
            }
            if(t>60){
                msg +=(60-d.s)+'s ';
            }
        }
        
        
    }
    return msg
}
export const hexToRGB = (hex_value) => {
    const numericValue = parseInt(hex_value.replace('#',''), 16);
    const r = numericValue >> 16 & 0xFF;
    const g = numericValue >> 8 & 0xFF;
    const b = numericValue & 0xFF;
    return `${r}, ${g}, ${b}`
} 
export function validateCPF(document) {
    document = document.replace(/[^\d]/g, ""); // Remove caracteres não numéricos
  
    if (document.length === 11) {
      // Se tem 11 dígitos, é CPF
      if (/^(.)\1+$/.test(document)) return false; // Verifica se não é uma sequência de números repetidos
  
      let sum = 0;
      for (let i = 0; i < 9; i++) sum += parseInt(document.charAt(i)) * (10 - i);
      let checkDigit1 = 11 - (sum % 11);
      if (checkDigit1 >= 10) checkDigit1 = 0;
  
      sum = 0;
      for (let i = 0; i < 10; i++) sum += parseInt(document.charAt(i)) * (11 - i);
      let checkDigit2 = 11 - (sum % 11);
      if (checkDigit2 >= 10) checkDigit2 = 0;
  
      return (
        checkDigit1 === parseInt(document.charAt(9)) &&
        checkDigit2 === parseInt(document.charAt(10))
      );
    } else {
      return false; 
    }
}
export function validateCNPJ(document) {
    document = document.replace(/[^\d]/g, ""); // Remove caracteres não numéricos
  
   if (document.length === 14) {
      // Se tem 14 dígitos, é CNPJ
      if (/^(.)\1+$/.test(document)) return false; // Verifica se não é uma sequência de números repetidos
  
      let size = document.length - 2;
      let numbers = document.substring(0, size);
      let digits = document.substring(size);
      let sum = 0;
      let pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += parseInt(numbers.charAt(size - i)) * pos--;
        if (pos < 2) pos = 9;
      }
      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != parseInt(digits.charAt(0))) return false;
  
      size = size + 1;
      numbers = document.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += parseInt(numbers.charAt(size - i)) * pos--;
        if (pos < 2) pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != parseInt(digits.charAt(1))) return false;
  
      return true;
    } else {
      return false; 
    }
}
  