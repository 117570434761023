import Painel from "./modelos/Painel";  
import 'animate.css'; 
const Layout=(props)=>{ 
    var page = null 
    console.log(props.lay.modelo);
    switch (props.lay.modelo) {    
        default:
            page= <Painel content={props.children} page={props.page}  url={props.url}/>
        break;
        
    } 
    return page;
}
export default Layout;