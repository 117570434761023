import { useLocation } from "react-router-dom";  
import { cfg } from "./dados/dados";
import Layout from "./layout/Layout";

export const Theme = () => {
    const location = useLocation();  
    var pag=[];
    var url = (location.pathname+'/').replace('//','/');
    url.split('/').forEach((path)=>{
        if(path!=''){
            pag.push(path);
        }
    });   
    var lay = {}
    cfg.layout.forEach((l)=>{  
        if(l.slug=='painel'){
            lay = l;
        }
    })   
    return (
        <> 
            <Layout page={pag} url={url} lay={lay}  ></Layout>
        </>
    )
}