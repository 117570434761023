import { Box, Button, Flex, Img, Stack, Tag, Text,Icon, Spinner, useToast, useClipboard } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {Helmet} from 'react-helmet'    
import {user,conectarServer,desconectarServer, GM} from "../../conn/WebPainel";
// pages
  
import { LuCheckCheck, LuCopy, LuDoorClosed, LuQrCode } from "react-icons/lu";
import QRCode from "react-qr-code"; 
import { TempoCronometro } from "../../Function";
const LayoutPainel=(props)=>{ 
    const toast = useToast(); 
    const { onCopy,value:valueCopy,  setValue} = useClipboard('');
    const base =useRef();
    const [web,setWeb] = useState(null); 
    const [reload,setReload] = useState(null); 
    const [session,setSession] = useState(null)
    const [dados,setDados] = useState(null)
    
    var conteudo    =   null;  

  
    useEffect(()=>{
        if(session !== null && web == null){
            conectarServer(session);
            setWeb(true);
        } 
     },[session]);  

    
    useEffect(()=>{
        var interV = setInterval(()=>{ 
            if( reload!==JSON.stringify(GM) && Object.keys(GM).length>0){ 
                setReload(JSON.stringify(GM));
                setDados(GM); 
            }  
            if((new Date().getTime()) - (parseInt(session)+60*60000)>0){
                console.log('Expirado')
                var temp = new Date().getTime();
                localStorage.setItem('session',temp); 
                setSession(temp);
                window.location.reload();
            }
        },1000) 
        return ()=>{ 
            clearInterval(interV);
            desconectarServer();
        }
    },[])    
     

    if(session == null){
        var temp = new Date().getTime();
        if(localStorage.getItem('session')!==null){
            temp = parseInt(localStorage.getItem('session'));
        }else{
            localStorage.setItem('session',temp); 
        }
        setSession(temp);
        conteudo= <Stack position={'relative'}  w='100vw' p='16px' h='100vh' overflowX={'hidden'} overflowY={'auto'} bg='#FAFAFA' zIndex={'1'} align='center' justify={{base:'flex-start',md:'center'}}>
            <Stack>
                <Flex gap={'20px'}>
                    <Spinner/> <Text color={'#000000'}>Validando Dados...</Text>
                </Flex> 
            </Stack> 
        </Stack> 
    }else{ 
        if(dados == null){
            conteudo= <Stack position={'relative'}  w='100vw' p='16px' h='100vh' overflowX={'hidden'} overflowY={'auto'} bg='#FAFAFA' zIndex={'1'} align='center' justify={{base:'flex-start',md:'center'}}>
                <Stack>
                    <Flex gap={'20px'}>
                        <Spinner/> <Text color={'#000000'}>Gerando  Pagamento...</Text>
                    </Flex> 
                </Stack> 
            </Stack>
        }else{ 
            console.log(dados.status);
            if(dados.status == "paid"){
                var expira = parseInt(session)+(60*6000); 
                conteudo= <Stack position={'relative'}  w='100vw' p='16px' h='100vh' overflowX={'hidden'} overflowY={'auto'} bg='#FAFAFA' zIndex={'1'} align='center' justify={{base:'flex-start'}}>
                    <Stack align={'center'} maxW='680px' pt={'30px'}>
                        <Stack w={'100px'} h='100px' bg='#5BB72E'borderRadius={'100%'} align='center' mb={'10px'} justify={'center'}> 
                            <Icon as={LuCheckCheck} color='#ffffff' fontSize={'40px'}/>
                        </Stack>
                        <Text color={'#000000'} fontWeight='bold' fontSize={{base:'18px',md:'24px'}}>Pagamento realizado com sucesso!</Text>
                        <Text color={'#000000'}  fontSize={{base:'14px',md:'16px'}}>Em breve você receberá um e-mail com todos os detalhes do pagamento</Text>
                        <Tag colorScheme={'gray'} bg='#EBEBEB' size='lg' p={'10px 20px'} borderRadius='15px' w={'fit-content'}>
                            <Text color={'#000000'}  fontWeight='bold'  fontSize={{base:'14px',md:'16px'}}>PEDIDO:<Text pl={"6px"} as='span' color={'#5AB732'} fontWeight='bold'fontSize={{base:'14px',md:'16px'}}>#{session}</Text> - APROVADO</Text>
                        </Tag>
                        <Button mt='20px' onClick={()=>{ 
                             window.close('','_parent','');
                        }} colorScheme='red' leftIcon={<LuDoorClosed/>} >Finalizar Pedido</Button> 
    
                    </Stack>
            
                </Stack>
            }else{ 
               
                if(dados.pix!==valueCopy){
                    setTimeout(()=>{
                        setValue(dados.pix);
                    }) 
                } 
                var expira = parseInt(session)+(60*6000);
    
                conteudo= <Stack position={'relative'}  w='100vw' p='16px' h='100vh' overflowX={'hidden'} overflowY={'auto'} bg='#FAFAFA' zIndex={'1'} align='center' justify={{base:'flex-start'}}>
                    <Stack align={'center'} maxW='680px' pt={'30px'}>
                        <Stack w={'100px'} h='100px' bg='#5BB72E'borderRadius={'100%'} align='center' mb={'10px'} justify={'center'}> 
                            <Icon as={LuQrCode} color='#ffffff' fontSize={'40px'}/>
                        </Stack>
                        <Text color={'#000000'} fontWeight='bold' fontSize={{base:'18px',md:'24px'}}>Seu pagamento foi gerado com sucesso!</Text>
                        <Text color={'#000000'}  fontSize={{base:'14px',md:'16px'}}>Realize o pagamento com o QRCODE abaixo ou clique no copia e cola para salvar o link de pagamento</Text>
                        <Tag colorScheme={'gray'} bg='#EBEBEB' size='lg' p={'10px 20px'} borderRadius='15px' w={'fit-content'}>
                            <Text color={'#000000'}  fontWeight='bold'  fontSize={{base:'14px',md:'16px'}}>PEDIDO:<Text pl={"6px"} as='span' color={'#5AB732'} fontWeight='bold'fontSize={{base:'14px',md:'16px'}}>#{session}</Text> - STATUS AGUARDANDO PAGAMENTO DO QRCODE</Text>
                        </Tag>
                        <Text fontSize={'12px'} color='orange.400' fontWeight='bold'  >Gerado em: { new Date(session).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'})}</Text>
                        
                        <Stack align={'center'} gap='0' borderRadius={'8px'} bg='#ffffff' border={'2px solid'} borderColor='gray.200' p='30px'>
                            <Flex gap={'26px'}  direction={{base:'column',md:'row'}}   align='center'>
                                <Stack w={'158px'} >
                                    <QRCode value={dados.pix}  bgColor="transparent" size={158}  /> 
                                </Stack> 
                                <Stack w={'full'}>
                                    <Flex>
                                        <Text color={'#000000'} fontWeight='600'fontSize={{base:'14px',md:'16px'}} textAlign='left'>1. Abra o app do seu banco ou instituição financeira e entre no ambiente Pix.</Text>
                                    </Flex>
                                    <Flex>
                                        <Text color={'#000000'} fontWeight='600'fontSize={{base:'14px',md:'16px'}} textAlign='left'>2. Escola a opção Pagar com QR Code e escaneie o código ao lado.</Text>
                                    </Flex>
                                    <Flex>
                                        <Text color={'#000000'} fontWeight='600'fontSize={{base:'14px',md:'16px'}} textAlign='left'>3. Confirme as informações e finalize o pagamento.</Text>
                                    </Flex> 
                                    <Flex justify={{base:'center',md:'flex-start'}} gap={'10px'}>
                                        <Text     textAlign='left' color={'#000000'} fontWeight='bold'fontSize={{base:'18px',md:'24px'}}> Expira em:</Text><Text     textAlign='left' color={'#5AB732'} fontWeight='bold'fontSize={{base:'18px',md:'24px'}}> <TempoCronometro time={expira} view='tempo' /></Text>
                                    </Flex> 
                                   
                                </Stack>
                            </Flex> 
                        </Stack>
                        <Stack align={'center'} gap='0'>
                            <Text color={'#000000'} fontWeight='600'fontSize={{base:'14px',md:'16px'}}>Pague com <b>PIX</b> em qualquer dia e qualquer hora!</Text>
                            <Text color={'#000000'} fontWeight='600'fontSize={{base:'14px',md:'16px'}}>O pagamento é seguro, instantâneo, prático e pode ser feito em poucos segundos.</Text>
                        </Stack>
                        <Button onClick={()=>{
                            onCopy();
                            toast({
                                title: 'Pix copiado',
                                description: 'Cole o Pix copia e cola no ambiente Pix',
                                status: 'success',
                                position:'top-center',
                                duration: 3000,
                                isClosable: true,
                            }); 
                        }} colorScheme='green' leftIcon={<LuCopy/>} >
                            Pagar com  PIX  copia e cola
                        </Button>
                        <Stack align={'center'} gap='0'>
                            <Text color={'gray.600'} fontWeight='600' fontSize={'12px'}>Após o pagamento, podemos levar alguns segundos para a confirmação.</Text>
                            <Text color={'gray.600'} fontWeight='600' fontSize={'12px'}>Não se preocupe, você será avisado assim que confirmado!</Text> 
                        </Stack>
                        
    
                    </Stack>
            
                </Stack>
            }
            
        }
        
    }
    
    // else if(user==null){
       
    //     conteudo= <Box position='relative' w={'100vw'} h='100vh' bg={'tema.bg001'}>
    //         <div className="bgVideo" style={{zIndex:1}} > 
    //             <div className="videoBox"  dangerouslySetInnerHTML={{ __html: `
    //                 <video playsinline loop autoPlay muted autobuffer>
    //                 <source src="/assets/video/bg.mp4"}" type="video/mp4" />  
    //                 Your browser does not support the video tag. I suggest you upgrade your browser.
    //             </video>` }}/>   
    //         </div> 
    //     </Box>
    // }else if(typeof user.usuario =='undefined'){   
    //     if(pag.login){ 
    //         pag = PagePainel[0];
    //     }   
    //     const ViewPage = Pages[pag.page];
    //     conteudo= (
    //         <Box position='relative'>

    //             <Box zIndex={'1'} position={'fixed'} top={'50%'} bg={cfg.theme.cores.bg001} left={'50%'} h={'100vh'} w={'100vw'} transform={'translateX(-50%) translateY(-50%)'} >
    //                 <div className="bgVideo" style={{zIndex:1}} > 
    //                     <div className="videoBox"  dangerouslySetInnerHTML={{ __html: `
    //                         <video playsinline loop autoPlay muted autobuffer>
    //                         <source src="/assets/video/bg.mp4"}" type="video/mp4" />  
    //                         Your browser does not support the video tag. I suggest you upgrade your browser.
    //                     </video>` }}/>   
    //                 </div> 
    //             </Box>
    //             <Flex zIndex={'2'} position={'relative'} ref={base} direction={'column'}  bg={'transparent'} fontFamily={cfg.theme.padrao.font} minH={'100vh'} overflowX={'hidden'} overflowY={'auto'}>
    //                 <ViewPage  pag={pag} page ={props.page} url={props.url}/>
    //             </Flex>
    //         </Box>
    //     )  
    // }else{ 
    //     if(!pag.login){
    //         pag = PagePainel[0]; 
    //         conteudo= <Navigate to={pag.url} />; 
    //     }else{   
    //         const ViewPage = Pages[pag.page]; 
    //         conteudo= (
    //             <Flex position={'relative'}  w='100vw' h='100vh' overflowX={'hidden'} overflowY={'auto'}>
    //                 <Flex position='relative' bg={'tema.bg001'} maxW={'1280px'} m={'0 auto'}> 
    //                     <Box zIndex={'1'} position={'fixed'} top={'50%'} bg={cfg.theme.cores.bg001} left={'50%'} h={'100vh'} w={'100vw'} transform={'translateX(-50%) translateY(-50%)'} >
    //                         <div className="bgVideo" style={{zIndex:1}} > 
    //                             <div className="videoBox"  dangerouslySetInnerHTML={{ __html: `
    //                                 <video playsinline loop autoPlay muted autobuffer>
    //                                 <source src="/assets/video/bg.mp4"}" type="video/mp4" />  
    //                                 Your browser does not support the video tag. I suggest you upgrade your browser.
    //                             </video>` }}/>  
    //                             <div className='mask'></div>
    //                         </div> 
    //                     </Box>
    //                     <Flex zIndex={'2'}    position={'relative'}  ref={base} direction={'column'}  bg={'transparent'} fontFamily={cfg.theme.padrao.font} w={'100vw'} h={'auto'}  >
    //                         <MenuMaker pag={pag} page ={props.page}/>
    //                         <Flex position={'relative'} pl={{base:'0',md:'285px'}} px={'15px'} top={{base: '60px' , md:'90px'}}  direction={'column'}   w='100%' h={'auto'}   >
    //                             <ViewPage pag={pag} page ={props.page}/> 
    //                         </Flex> 
    //                     </Flex>
    //                 </Flex>
    //             </Flex>
                 
    //         )
    //     }
        
    // }
    return <>
        <Helmet> 
            {/* <title>O4T - {pag.nome}</title>
            <meta name="title" content={cfg.nome} />
            <meta name="description" content={cfg.descricao} />

            <link rel="icon" href={'/assets/'+cfg.site+'/icon.ico'} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={"https://"+cfg.site} />
            <meta property="og:title" content={"https://"+cfg.nome} />
            <meta property="og:description" content={cfg.descricao} />
            <meta property="og:image" content={"https://"+cfg.site+"/assets/img/logoBanner.png"} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={"https://"+cfg.site} />
            <meta property="twitter:title" content={cfg.site} />
            <meta property="twitter:description" content={cfg.descricao} />
            <meta property="twitter:image" content={"https://"+cfg.site+"/assets/img/logoBanner.png"} /> */}
        </Helmet>  
        {conteudo}
    </>
    
}
export default  LayoutPainel;