import { io }   from "socket.io-client"; 
import md5 from 'md5'; 

export let web = {
    session     :   md5('cliPainel'),
    server      :   "recfaz.com:3001",
    socket      :   null,
    conectado   :   false,
    token       :   null 

}
export let user = null; 
export let GM = {};
export let srvTime = 0; 
export let saldos = {};
export const setSession = function(send){ 
    localStorage.setItem(web.session,send.token); 
    web.token       =   send.token; 
    user            =   send.dados;  
} 
export const conectarServer = function(session){ 
    if(!web.conectado){
        web.token     =   localStorage.getItem(web.session);  
        if(typeof web.token == 'undefined'|| web.token == '' || web.token == null){
            user = {};
        } 
        web.socket    =  new io("wss://"+web.server,{ 
            withCredentials: true, 
            query: {
                session: session
            }
        });
        console.log('conectar')
        let interV = null; 
        web.socket.on("connect", (socket) => {    
            try {  if(web.token!==''){ web.socket.emit('dadosGeral');} } catch (error) { } 
            web.conectado =true;
            clearInterval(interV);
            var interV =  setInterval(()=>{  
                try {  web.socket.emit('autologin',web.token);  } catch (error) { }
                try {  if(web.token!==''){ web.socket.emit('dadosGeral');} } catch (error) { } 
             },1000) 
        });  
        web.socket.on("timeServer", (time) => {  
            srvTime = time;
        });
        web.socket.on("checkpix", (d) => {    
            GM = d;
        }); 
        web.socket.on("disconnect", () => {  
            clearInterval(interV);
            GM={};
            console.log('desconectado')
            web.conectado =false;
        }); 
    }
    
}
export const sendForm=(props)=>{
    const {form,modulo,cmd,callback}= props;
    web.socket.removeAllListeners('sendForm').on("sendForm", (d) => {  
        web.socket.removeAllListeners('sendForm');
       if(d.modulo ==props.modulo && d.cmd ==props.cmd){
            try {
                callback(d.result)
            } catch (error) {
                
            }
       }
    });
    web.socket.emit('sendForm',{form,modulo,cmd}); 
}
export const desconectarServer = async function(){ 
    if(web.socket != null){ 
        web.socket.close();
        web.socket =null;
        web.conectado = false;  
    }
} 
